:root {
  --main: #1b262c;
  --sub: #27496d;
  --highligh: #0c7b93;
  --important: #00a8cc;
  --contrast: #d1ccc0;
}

html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

body {
  background-color: var(--main);
  color: var(--contrast);
}

section {
  width: 100%;
  padding: 1rem;
}

section h1 {
  text-align: center;
  padding: 0.5rem 0;
  background-color: var(--highligh);
  margin: 0 -1rem 1rem -1rem;
}
